const referenciasData  = {
    "data":[
        {
            "id":"1",
            "title":"I. Introducción a la conducta responsable en investigación",
            "content":[
                {
                    "title":"Torjesen I. Research bodies try to strengthen the integrity of UK research. BMJ 2012;344:e2677",
                    "linkDir":"https://www.bmj.com/content/344/bmj.e2677",
                },
                {
                    "title":"Steneck N. Introduction to the Responsible Conduct of Research. ORI 2007.",
                    "linkDir":"https://ori.hhs.gov/documents/rcrintro.pdf",
                },
                {
                    "title":"Singapore Statement on Research Integrity. 2010",
                    "linkDir":"https://www.singaporestatement.org/downloads/singpore%20statement_A3size.pdf",
                },
                {
                    "title":"Declaración de Singapur sobre Integridad Científica. 2010",
                    "linkDir":"https://www.singaporestatement.org/Translations/SS_Spanish.pdf",
                },
                {
                    "title":"Stanley G. Teaching the Responsible Conduct of Research in Humans (RCRH). Chapter 1: The ethical basis of RCRH.",
                    "linkDir":"https://ori.hhs.gov/education/products/ucla/chapter1/Chapter1.pdf",
                },
                {
                    "title":"Institute of Medicine and National Research Council. Integrity in Scientific Research: Creating an Environment that Promotes Responsible Conduct. 2002",
                    "linkDir":". https://www.nap.edu/catalog.php?record_id=10430#toc",
                },


            ]
        },
        {
            "id":"2",
            "title":"II. Mala conducta científica",
            "content":[
                {
                    "title":"Kakuk P. The Legacy of the Hwang Case: Research Misconduct in Biosciences. Sci. Eng. Ethics. December 2009;15(4):545-62",
                    "linkDir":"https://link.springer.com/article/10.1007%2Fs11948-009-9121-x",
                },
                {
                    "title":"Universidad de Washington. Biomedical Research Integrity Cases. Teaching Cases & Background Materials",
                    "linkDir":"https://ori.hhs.gov/education/products/burke_washington/burke.pdf",
                },
                {
                    "title":"Kalichman M. Overview: Underserved Areas of Education in the Responsible Conduct of Research: Authorship. Sci Eng Ethics 2011; 17(2): 335-339.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3428198/",
                },
                {
                    "title":"Kukolja S., Taradi M., Knezevic T., Dogas Z. Students come to medical schools prepared to cheat: a multi-campus investigation. J Med Ethics 2010;36(11):666-70",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/20797977",
                },
                {
                    "title":"Mitcham C. Ethics and Scientific Research. A Selective Chronology with References. 2003",
                    "linkDir":"https://newdirections.unt.edu/resources/ethics_chronology.pdf",
                },
                {
                    "title":"Horner J., Minifie F. Research Ethics I: Responsible Conduct of Research (RCR) – Historical and Contemporary Issues Pertaining to Human and Animal Experimentation. J Speech Lang Hear Res 2011; 54:303-329.",
                    "linkDir":"https://jslhr.asha.org/cgi/content/abstract/54/1/S303",
                },
                {
                    "title":"Fanelli D. How Many Scientists Fabricate and Falsify Research? A Systematic Review and Meta-Analysis of Survey Data. Plos One. May 2009; 4(5): e5738",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2685008/",
                },
                {
                    "title":"Steneck N. Fostering integrity in research: Definitions, current knowledge, and future directions. Sci. Eng. Ethics. 2006; 12(1):53-74.",
                    "linkDir":"https://link.springer.com/article/10.1007/PL00022268",
                },
                {
                    "title":"Steneck N. Introduction to the Responsible Conduct of Research. ORI 2007. ",
                    "linkDir":"https://ori.hhs.gov/documents/rcrintro.pdf",
                },
                {
                    "title":"U.S. Department of Health and Human Services. Public Health Service Policies on Research Misconduct. DHHS. 2005.",
                    "linkDir":"https://ori.hhs.gov/documents/42_cfr_parts_50_and_93_2005.pdf",
                },
                {
                    "title":"Godlee F. Research misconduct is widespread and harms patients. BMJ. 2012;344:e14.",
                    "linkDir":"https://www.bmj.com/content/344/bmj.e14",
                },
                {
                    "title":"Weissmann G. Science fraud: from patchwork mouse to patchwork data. FASEB J. 2006;20(6):587-90.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/16581962",
                },
                {
                    "title":"Universidad Peruana Cayetano Heredia. Políticas para responder Alegaciones de Mala Conducta Científica. 2002. ",
                    "linkDir":"https://www.upch.edu.pe/vrinve/doc/Polrealemcc.pdf",
                },
                {
                    "title":"Montgomerie B., Birkhead T. A Beginner’s Guide to Scientific Misconduct. ISBE Newsletter. May 2005;17(1):16-2",
                    "linkDir":"https://www.behavecol.com/pages/pdf/Montgomerie&Birkhead_vol17%281%29.pdf",
                },
                {
                    "title":"Swiss Academies of Arts and Sciences.Integrity in scientific research: Principles and procedures.2008",
                    "linkDir":"https://www.akademien-schweiz.ch/en/index/Portrait/Kommissionen-AG/Wissenschaftliche-Integritaet.html",
                },
                {
                    "title":"Bosch X. Dealing with scientific misconduct: Europe needs policies for good scientific practice and for investigating misconduct allegations. BMJ 2007;335:524",
                    "linkDir":"https://www.bmj.com/content/335/7619/524",
                },
                {
                    "title":"Bosch X. Safeguarding good scientific practice in Europe: the increasingly global reach of science requieres the harmonization of standards. EMBO Rep. 2010;11(4):252-257",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2854597/",
                },
                {
                    "title":"Bossi E. Scientific integrity, misconduct in science. Swiss Med Wkly 2010;140(13):183-186.",
                    "linkDir":"https://www.smw.ch/docs/smw/archiv/pdfcontent/smw-12981.pdf",
                },
                {
                    "title":"Peh W., Ng K. Effective Medical Writing: pointers to getting your article published. Publication ethics and scientific misconduct. Singapore Med J 2010;51(12):908-911. ",
                    "linkDir":"https://smj.sma.org.sg/5112/5112emw1.pdf",
                },
                {
                    "title":"Bacallao J., Barber A., Roca G. Las conductas impropias en la actividad científica. Rev Cubana Salud Pública 2003;29(1):61-4",
                    "linkDir":"https://bvs.sld.cu/revistas/spu/vol29_1_03/spu09103.pdf",
                },
                {
                    "title":"Centro de Investigación de Enfermedades Tropicales de la Marina de los Estados Unidos. Programa de Administración de la Investigación. Manual de Procedimientos para el Manejo de una Conducta Responsable en las Investigaciones con Participantes Humanos. Marzo 2011",
                    "linkDir":"https://www.med.navy.mil/sites/NAMRU6/Documents/ProcedimientosManejo.pdf",
                },
                {
                    "title":"Ushma N. Publish or perish but at what Cost?. J Clin Invest 2008;118(7):2368.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2439458/"
                },
                {
                    "title":"cholze S. Setting standards for scientists: For almost ten years, COMEST has advised UNESCO on the formulation of ethical guidelines. EMBO Rep. 2006;7(Spec No): S65-S67.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1490296/#illuSI"
                },
                {
                    "title":"Stern J., Elliot D. “The ethics of scientific research: A guidebook for course development”. 1997.",
                    "linkDir":""
                }
            ]
        },
        {
            "id":"3",
            "title":"III. Plagio",
            "content":[
                {
                    "title":"Bazdaric K., Bilic-Zulle L., Brumini G., Petrovecki M. Prevalence of plagiarism in recent submissions to the Croatian Medical Journals. Sci Eng Ethics. 2012;18(2):223-39",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/22207497",
                },
                {
                    "title":"Cole A. Plagiarism in graduate medical education. Fam Med. 2007;39(6)436-8.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/17549654",
                },
                {
                    "title":"Comisión de Ética, Facultad de Medicina, Universidad de Chile. En referencia al plagio intelectual. Documento de la Comisión de Ética de la Facultad de Medicina de la Universidad de Chile. Rev Med Chile. 2008;136(5):653-8.",
                    "linkDir":"https://www.scielo.cl/pdf/rmc/v136n5/art16.pdf",
                },
                {
                    "title":"Garner H. Combating unethical publications with plagiarism detection services. Urol Oncol. 2011;29(1):95-9",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/21194644",
                },
                {
                    "title":"Huamaní C., Dulanto-Pizzorni A., Rojas-Revoredo V. ´Copiar y pegar´en investigaciones en el pregrado: haciendo mal uso de Internet. An Fac Med (Lima). 2008;69(2):117-9",
                    "linkDir":"https://www.scielo.org.pe/pdf/afm/v69n2/a10v69n2.pdf",
                },
                {
                    "title":"Mavrinac M, Brumini G, Bilic-Zulle L, Petrovecki M. Construction and validation of attitudes toward plagiarism questionnaire. Croat Med J. 2010;51(3):195-201.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/20564761",
                },
                {
                    "title":"Notice of plagiarism and copyright violation. Am J Trop Med Hyg. 2012;87(2):379.",
                    "linkDir":"https://www.ajtmh.org/content/87/2/379.full",
                },
                {
                    "title":"Pupovac V., Bilic-Zulle L., Mavrinac M., Petrovecki M. Attitudes toward plagiarism among pharmacy and medical biochemistry students—Cros-sectional survey study. Biochemia Medica. 2010;20(3):307-13",
                    "linkDir":"https://hrcak.srce.hr/59293",
                },
                {
                    "title":"Reyes H. El plagio en las publicaciones científicas. Rev Med Chile. 2009;137(1):7-9.",
                    "linkDir":"https://www.scielo.cl/pdf/rmc/v137n1/art01.pdf",
                },
                {
                    "title":"Roig M. Avoiding plagiarism, self-plagiarism, and other questionable writing practices: A guide to ethical writing. Saint Johns University; 2006.",
                    "linkDir":"https://www.ori.dhhs.gov/education/products/plagiarism/plagiarism.pdf",
                },
                {
                    "title":"Rojas-Revoredo V., Huamaní C., Mayta-Tristán P. Plagio en publicaciones científicas en el pregrado: experiencias y recomendaciones. Rev Med Chile. 2007;135(8):1087-8",
                    "linkDir":"https://www.scielo.cl/pdf/rmc/v135n8/art21.pdf",
                },
                {
                    "title":"Ryan G., Bonanno H., Krass I., Scouller K., Smith L. Undergraduate and postgraduate pharmacy students’ perceptions of plagiarism and academic honesty. Am J Pharm Educ. 2009;73(6):105. ",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/19885074",
                },
                {
                    "title":"Saldaña-Gastulo J., Quezada-Osoria C., Peña-Oscuvilca A., Mayta-Tristán P. Alta frecuencia de plagio en tesis de medicina de una universidad pública peruana. Rev Peru Med Exp Salud Publica. 2010;27(1):63-67.",
                    "linkDir":"https://www.scielo.org.pe/pdf/rins/v27n1/a11v27n1.pdf",
                },
                {
                    "title":"Segal S., Gelfand B., Hurwitz S., Berkowitz L., Ashley S., Nadel E. Plagiarism in residency application essays. Ann Intern Med. 2010;153(2):112-20. ",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/20643991",
                },
                {
                    "title":"Bilic-Zulle L., Frkovic V., Turk T., Azman J., Petrovecki M. Prevalence of plagiarism among medical students. Croat Med J 2005;46(1):126-31.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/15726686",
                },
                {
                    "title":"Fang F., Steen R., Casadevall A. Misconduct accounts for the majority of retracted scientific publications. Proc Natl Acad Sci U S A 2012; 109(42): 17028-33.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/?term=Misconduct+accounts+for+the+majority+of+retracted+scientific+publications",
                },
                {
                    "title":"Fischer B., Zigmond M. Educational approaches for discouraging plagiarism. Urol Oncol 2011; 29(1): 100-3.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/?term=Educational+approaches+for+discouraging+plagiarism",
                },
                {
                    "title":"Plagiarism.org",
                    "linkDir":"",
                },
                {
                    "title":"Roig M. Avoiding plagiarism, self-plagiarism, and other questionable writing practices: A guide to ethical writing. Saint Johns University.",
                    "linkDir":"https://www.ori.dhhs.gov/education/products/plagiarism/plagiarism.pdf",
                },
                {
                    "title":"Yilmaz I. Plagiarism? No, we're just borrowing better English. Nature 2007;449(7163):658.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/?term=Plagiarism%3F+No%2C+we%27re+just+borrowing+better+English",
                },
            ]
        },
        {
            "id":"4",
            "title":"IV. Autoría responsable",
            "content":[
                {
                    "title":"Flanagin A., Carey A., Fontanarosa P., Phillips S., Pace B., Lundberg G., Rennie D. Prevalence of articles with honorary authors and ghost authors in peer-reviewed medical journals. JAMA 1998;280(3):222-4",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/9676661",
                },
                {
                    "title":"Gollogly L., Momen H. Ethical dilemmas in scientific publication: pitfalls and solutions for editors. Rev Saude Publica 2006;40(Spec No): 24-9.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/?term=Ethical+dilemmas+in+scientific+publication%3A+pitfalls+and+solutions+for+editors",
                },
                {
                    "title":"ICMJE (International Committee of Medical Journal Editors). Uniform Requirements for Manuscripts Submitted to Biomedical Journals.",
                    "linkDir":"https://www.icmje.org/",
                },
                {
                    "title":"ICMJE (International Committee of Medical Journal Editors). Ethical Considerations in the Conduct and Reporting of Research: Authorship and Contributorship.",
                    "linkDir":"https://www.icmje.org/ethical_1author.html",
                },
                {
                    "title":"Rennie D., Flanagin A. Authorship! Authorship! Guests, ghosts, grafters, and the two-sided coin. JAMA 1994;271(6):469-471.",
                    "linkDir":"https://jama.jamanetwork.com/article.aspx?articleid=364559",
                },
                {
                    "title":"Street J., Rogers W., Israel M. Credit where credit is due? Regulation, research integrity and the attribution of authorship in the health sciences. Soc Sci Med 2010;70(9):1458-65.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/20172638",
                },
                {
                    "title":"Tisdale J. Integrity in Authorship and Publication. Can J Hosp Pharm 2009;62(6): 441-442.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2827013/",
                },
            ]
        },
        {
            "id":"5",
            "title":"V. Publicación responsable",
            "content":[
                {
                    "title":"Notice of plagiarism and copyright violation. Am J Trop Med Hyg. 2012;87(2):379.",
                    "linkDir":"https://www.ajtmh.org/content/87/2/379.full",
                },
                {
                    "title":"Roig M. Avoiding plagiarism, self-plagiarism, and other questionable writing practices: A guide to ethical writing. Saint Johns University. 2006 ",
                    "linkDir":"https://www.ori.dhhs.gov/education/products/plagiarism/plagiarism.pdf",
                },
                {
                    "title":"Fang F., Steen G., Casadevall A. Misconduct accounts for the majority of retracted publications. Proc Natl Acad Sci U S A 10.1073/pnas.1212247109",
                    "linkDir":"https://www.pnas.org/content/early/2012/09/27/1212247109.abstract",
                },
                {
                    "title":"Singapore Statement on Research Integrity. 2010",
                    "linkDir":"https://www.singaporestatement.org/downloads/singpore%20statement_A3size.pdf",
                },
                {
                    "title":"COPE (Committee for Publication Ethics): Promoting research integrity in publications.",
                    "linkDir":"https://publicationethics.org/",
                },
                {
                    "title":"ICMJE (International Committee of Medical Journal Editors). Uniform Requirements for Manuscripts Submitted to Biomedical Journals. ",
                    "linkDir":"https://www.icmje.org/",
                },
            ]
        },
        {
            "id":"6",
            "title":"VI. Conflictos de interés",
            "content":[
                {
                    "title":"Steneck N. Introduction to the Responsible Conduct of Research. ORI 2007.",
                    "linkDir":"https://ori.hhs.gov/documents/rcrintro.pdf",
                },
                {
                    "title":"Horner J., Minifie F. Research Ethics III: Publication Practices and Authorship, Conflicts of Interest, and Research Misconduct. J Speech Lang Hear Res 2011; 54:346-365.",
                    "linkDir":"https://jslhr.asha.org/cgi/content/abstract/54/1/S346",
                },
                {
                    "title":"Thompson D. Understanding Financial Conflicts of Interest. N Engl J Med 1993;329(8): 573-6.",
                    "linkDir":"https://www.nejm.org/doi/full/10.1056/NEJM199308193290812",
                },
                {
                    "title":"Murray T., Johnston J. Trust and Integrity in Biomedical Research: The Case of Financial Conflicts of Interest. The Johns Hopkins University Press 2010.",
                    "linkDir":"Murray T., Johnston J. Trust and Integrity in Biomedical Research: The Case of Financial Conflicts of Interest. The Johns Hopkins University Press 2010",
                },
                {
                    "title":"Campbell E., Weissman J., Ehringhaus S., Rao S., Moy B., Feibelmann S., Goold S. Institutional Academic-Industry Relationships. JAMA. 2007; 298(15):1779-86",
                    "linkDir":"https://jama.jamanetwork.com/article.aspx?articleid=209192",
                },
                {
                    "title":"Patsopoulos N., Ioannidis J., Analatos A. Origin and funding of the most frequently cited papers in medicine: database analysis. BMJ 2006;332(7549):1061-4.",
                    "linkDir":"https://www.bmj.com/content/332/7549/1061?view=long&pmid=16547014",
                },
                {
                    "title":"Bekelman J., Li Y., Gross C. Scope and Impact of Financial Conflicts of Interest in Biomedical Research. A Systematic Review. JAMA. 2003;289(4):454-65",
                    "linkDir":"https://jama.jamanetwork.com/article.aspx?articleid=195843",
                },
                {
                    "title":"Declaración de la Asociación Medica Mundial sobre Conflictos de Interés. Adoptada por la 60a Asamblea General, Nueva Delhi, Octubre 2009. ",
                    "linkDir":"https://www.wma.net/es/30publications/10policies/i3/",
                },
                {
                    "title":"ICMJE (International Committee of Medical Journal Editors). Uniform Requirements for Manuscripts Submitted to Biomedical Journals.",
                    "linkDir":"https://www.icmje.org/",
                },
                {
                    "title":"JAMA Internal Medicine Instructions for Authors.",
                    "linkDir":"https://archinte.jamanetwork.com/public/instructionsForAuthors.aspx",
                },
                {
                    "title":"The Lancet: Info for Authors",
                    "linkDir":"https://www.thelancet.com/lancet-information-for-authors",
                },
            ]
        },
        {
            "id":"7",
            "title":"VII. Mentoría",
            "content":[
                {
                    "title":"American Psychological Association. Introduction to Mentoring: A Guide for Mentors and Mentees. Presidential Task Force. 2006",
                    "linkDir":"https://www.apa.org/education/grad/intro-mentoring.pdf",
                },
                {
                    "title":"National Academy of Science (NAS). Adviser, Teacher, Role Model, Friend: On Being a Mentor to Students in Science and Engineering. Washington, D.C.: National Academy Press. 1997",
                    "linkDir":"https://www.nap.edu/catalog.php?record_id=5789#toc",
                },
                {
                    "title":"University of Columbia. Responsible conduct of research: Mentoring.",
                    "linkDir":"https://ccnmtl.columbia.edu/projects/rcr/rcr_mentoring/foundation/index.html",
                },
                {
                    "title":"Mentoring.org",
                    "linkDir":"https://www.mentoring.org/",
                },
                {
                    "title":"Kwoh L. Reverse Mentoring Cracks Workplace : Top Managers Get Advice on Social Media, Workplace Issues From Young Workers. Wall Street Journal. 28 Nov 2011.",
                    "linkDir":"https://online.wsj.com/article/SB10001424052970203764804577060051461094004.html",
                },
                {
                    "title":"Sambunjak D., Straus S., Marusic A. Mentoring in Academic Medicine. A Systemic Review. JAMA 2006; 296(9):1103-1115.",
                    "linkDir":"https://jama.jamanetwork.com/article.aspx?articleid=203257",
                },
                {
                    "title":"Frei E., Stamm M., Buddeberg-Fischer B. Mentoring programs for medical students – a review of the PubMed literature 2000-2008. BMC Med Educ. 2010; 10:32. ",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2881011/",
                },
                {
                    "title":"Meinel F., Dimitriadis K., von der Borch P., Stormann S., Niedermaier S., Fischer M. More mentoring needed? A cross-sectional study of mentoring programs for medical students in Germany. BMC Med Educ. 2011;11:68.",
                    "linkDir":"https://www.ncbi.nlm.nih.gov/pubmed/21943281",
                },
                {
                    "title":"Lee A., Dennis C., Campbell P. Nature’s guide for mentors. Having a good mentor early I your career can mean the difference between success and failure in any field. Nature 2007; 447(7146):791-797.",
                    "linkDir":"https://www.nature.com/nature/journal/v447/n7146/full/447791a.html",
                },
                {
                    "title":"Horner J., Minifie F. Research Ethics II: Mentoring, Collaboration, Peer Review, and Data Management and Ownership. J Speech Lang Hear Res 2011; 54:330-345.",
                    "linkDir":"https://jslhr.asha.org/cgi/content/abstract/54/1/S330",
                }
            ]
        }
    ]
}

export default referenciasData;
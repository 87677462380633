import * as React from "react";
import { useState } from 'react';
import styled from "styled-components";
import Simple from "@templates/Simple";
import referenciasData from "@data/Referencias";


const Page = () => {

    const [refer, setrefer] = useState(-1);


    return (
        <Simple title={"CRI: Referencias Bibliográficas"}>
            <Wrapper>
                <h1>Referencias Bibliográficas</h1>
                {referenciasData["data"].map((item, index) => {
                    return (
                        <ReferenciaWrappper key={`wrapper${item.id}${index}`}>
                            <h2
                                onClick={()=>{setrefer(index)}}
                            >
                                {item.title}
                            </h2>
                            {
                                refer === index
                                    ? (
                                        <ReferenciaListWrappper>
                                            <ol>
                                                {
                                                    referenciasData["data"][index]["content"].map((subitem, subindex) => {
                                                        return (
                                                            <li key={`referencialist${item}${index}${subitem}${subindex}`}>
                                                                <a href={subitem.linkDir} target={"_blank"} rel={"noreferrer"}>
                                                                    {subitem.title}
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ol>
                                        </ReferenciaListWrappper>
                                    )
                                    : null
                            }
                        </ReferenciaWrappper >
                    )
                })}
            </Wrapper>
        </Simple>
    )
}

export default Page;

const Wrapper = styled.div`

padding: 0 1em;
margin-bottom: 5em;

h1{
    font-size:25px;
    color:#6c757d;
}
h2{
    font-size:19.2px;
    color:#6c757d;
    padding:15px 0;
    border-bottom-color:rgb(255, 180, 0);
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    border-bottom-style:solid;

    cursor: pointer;
    :hover{
            color: var(--color1);
        }
}
`;

const ReferenciaWrappper = styled.div`

`;



const ReferenciaListWrappper = styled.div`
    li{
        font-size:16px;
        line-height:24px;
        padding:10px 0;
        color:#6c757d;
    }
    a:hover{
            color: var(--color1);
        }
`;